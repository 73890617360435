import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQuery } from "../../utils/headersApi"

export const rentalsApi = createApi({
  reducerPath: "rentalsApi",
  baseQuery,
  endpoints: (builder) => ({
    getRentalsListByUser: builder.query({
      query: () => ({
        url: "rentals",
      }),
    }),
    startRental: builder.mutation({
      query: (rental) => ({
        url: "rentals/start",
        method: "POST",
        body: rental,
      }),
    }),
    endRental: builder.mutation({
      query: (rental) => ({
        url: "rentals/end",
        method: "POST",
        body: rental,
      }),
    }),
    commentRental: builder.mutation({
      query: (rental) => ({
        url: "rentals/comment",
        method: "POST",
        body: rental,
      }),
    }),
    getEvidences: builder.query({
      query: (id) => ({
        url: `rentals/evidences/${id}`,
      }),
    }),
    setRanking: builder.mutation({
      query: (ranking) => ({
        url: "rentals/ranking",
        method: "POST",
        body: ranking,
      }),
    }),
    success: builder.mutation({
      query: (body) => ({
        url: "rentals/success",
        method: "POST",
        body: body,
      }),
    }),
  }),
})

export const {
  useGetRentalsListByUserQuery,
  useStartRentalMutation,
  useEndRentalMutation,
  useCommentRentalMutation,
  useGetEvidencesQuery,
  useSetRankingMutation,
  useSuccessMutation,
} = rentalsApi
