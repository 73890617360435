import { createSlice } from "@reduxjs/toolkit"
import dayjs from "dayjs"

const initialState = {
    monthIndex: dayjs().month(),
    smallCalendarDaySelected: null,
}

const slice = createSlice({
    name: "calendarSlice",
    initialState,
    reducers: {
        handleMonthIndex: (state, { payload }) => {
            state.monthIndex = payload
        },
        handleSmallCalendarDaySelected: (state, { payload }) => {
            state.smallCalendarDaySelected = payload
        },
    },
})

export const { handleMonthIndex, handleSmallCalendarDaySelected } = slice.actions
export default slice.reducer

export const selectMonthIndex = (state) => state.calendar.monthIndex
export const selectSmallCalendarDaySelected = (state) => state.calendar.smallCalendarDaySelected

