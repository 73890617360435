import { useEffect } from "react"
import { useQuery } from "../../hooks/useQuery"

export const RentalSuccess = () => {
  const query = useQuery()
  const payment_intent = query.get("payment_intent")
  const payment_intent_client_secret = query.get("payment_intent_client_secret")
  const redirect_status = query.get("redirect_status")

  useEffect(() => {

  }, [])

  return <div>
    <div>
      <span>RentalSuccess:</span>
    </div>
    <div>
      <span>{payment_intent}</span>
      <br />
      <span>{payment_intent_client_secret}</span>
      <br />
      <span>{redirect_status}</span>
    </div>
  </div>
}
