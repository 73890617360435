import { Box } from "@mui/material"
import { getMonth } from "../../utils/handleDate"
import { Header } from "./Header"
import { Sidebar } from "./Sidebar"
import { Month } from "./Month"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { selectMonthIndex } from "../../redux/slices/calendar"

export const Calendar = () => {
  const monthIndex = useSelector(selectMonthIndex)
  const [currentMonth, setCurrentMonth] = useState(getMonth())

  useEffect(() => {
    setCurrentMonth(getMonth(monthIndex))
  }, [monthIndex])

  return (
    <React.Fragment>
      <Box
        component={"div"}
        style={{
          height: "80vh",
          width: "75vw",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header />
        <Box component={"div"} style={{ display: "flex", flex: 1 }}>
          <Sidebar />
          <Month month={currentMonth} />
        </Box>
      </Box>
    </React.Fragment>
  )
}
