import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../utils/headersApi";

export const vehiclesApi = createApi({
	reducerPath: "vehicleApi",
	baseQuery,
	endpoints: (builder) => ({
		vehiclesAdd: builder.mutation({
			query: (body) => ({
				url: "vehicles/add",
				method: "POST",
				body,
			}),
		}),
		vehiclesGetByUser: builder.query({
			query: () => ({
				url: "vehicles/get-by-user",
			}),
		}),
		vehiclesGetPublicList: builder.query({
			query: () => ({
				url: "vehicles",
			}),
		}),
		vehiclesGetList: builder.query({
			query: () => ({
				url: "vehicles/list",
			}),
		}),
		vehicleGetBrandList: builder.query({
			query: () => ({
				url: "vehicles/brand-list",
			}),
		}),
		vehicleGetModelList: builder.mutation({
			query: (brand) => ({
				url: `vehicles/model-list/${brand}`,
				method: "GET",
			}),
		}),
		vehicleGetTypeList: builder.mutation({
			query: (model) => ({
				url: `vehicles/type-list/${model}`,
				method: "GET",
			}),
		}),
		vehicleGetColors: builder.query({
			query: () => ({
				url: "vehicles/colors",
			}),
		}),
		getViewDocument: builder.query({
			query: (path) => ({
				url: path,
				responseHandler: (response) => response.blob(),
			}),
			transformResponse: (response) => {
				return URL.createObjectURL(response);
			},
		}),
		vehicleValidateDocument: builder.mutation({
			query: (post) => ({
				url: "vehicles/document-validate",
				method: "POST",
				body: post,
			}),
		}),
		vehicleValidate: builder.mutation({
			query: (id) => ({
				url: `vehicles/${id}`,
				method: "PATCH",
			}),
		}),
		vehicleCreatePaymentIntent: builder.mutation({
			query: (post) => ({
				url: "vehicles/create-payment-intent",
				method: "POST",
				body: post,
			}),
		}),
		vehicleRental: builder.mutation({
			query: (post) => ({
				url: "vehicles/rental",
				method: "POST",
				body: post,
			}),
		}),
	}),
});

export const {
	useVehiclesAddMutation,
	useVehiclesGetByUserQuery,
	useVehiclesGetPublicListQuery,
	useVehiclesGetListQuery,
	useGetViewDocumentQuery,
	useVehicleGetBrandListQuery,
	useVehicleGetModelListMutation,
	useVehicleGetTypeListMutation,
	useVehicleGetColorsQuery,
	useVehicleValidateDocumentMutation,
	useVehicleValidateMutation,
	useVehicleCreatePaymentIntentMutation,
	useVehicleRentalMutation,
} = vehiclesApi;
