import { Box, Button, Grid, Icon } from "@mui/material"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
// import { Modal } from "../../components/General"
// import { Rental } from "../../components/Rental/Rental"
import { VehicleCarousel } from "../../components/Vehicles"
import { ViewCheckboxes } from "../../components/Vehicles/ViewCheckBoxes"
import { selectVehicleToRental } from "../../redux/slices/vehicles"
// import { Calendar } from "../../components/Calendar/Calendar"

export const VehicleRental = () => {
  const navigate = useNavigate()
  const vehicle = useSelector(selectVehicleToRental)

  const handleCancel = () => {
    navigate(-1)
  }

  const handleClickRental = () => {
    navigate("/rentals/calendar")
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <VehicleCarousel photos={vehicle.photos} auto={false} />
        </Grid>
        <Grid item md={6}>
          <ViewCheckboxes properties={vehicle.properties} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            fullWidth
            variant='outlined'
            color='error'
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6}>
          {/* TODO: pensar en como resolver este tema de los modales */}
          {/* <Modal
            title='Completa tu compra'
            description='Rentar este vehículo'
            icon={<Icon>attach_money</Icon>}
            textButton='Rentar'
            fullWidth={true}
            variant='contained'
            color='success'
          >
            <Rental />
          </Modal> */}
          <Button
            startIcon={<Icon>attach_money</Icon>}
            onClick={handleClickRental}
            fullWidth={true}
            variant='contained'
            color='success'
          >
            Rentar
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
