import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { CheckoutForm } from "./CheckoutForm"
import { useEffect, useState } from "react"
import { useVehicleCreatePaymentIntentMutation } from "../../redux/apis/vehicles"
import { CircularProgress } from "@mui/material"
import { useSelector } from "react-redux"
import { selectVehicleToRental } from "../../redux/slices/vehicles"
import PropTypes from "prop-types"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

export const StripeCheckout = ({ values }) => {
  const [clientSecret, setClientSecret] = useState("")
  const [vehicleCreatePaymentIntent, { isLoading }] =
    useVehicleCreatePaymentIntentMutation()
  const vehicle = useSelector(selectVehicleToRental)

  //TODO: send partner and check data is need for the rental

  useEffect(() => {
    vehicleCreatePaymentIntent({
      vehicle: { id: vehicle.id },
      start: values.start,
      days: values.days,
    })
      .then((res) => {
        console.log(res)
        const { data, error } = res
        if (error) {
          throw new Error(error.data.message)
        }
        if (data.op) {
          console.log(data.clientSecret)
          setClientSecret(data.clientSecret)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const appearance = {
    theme: "stripe",
  }
  const options = {
    clientSecret,
    appearance,
  }

  return (
    <>
      {isLoading && (
        <p>
          Loading <CircularProgress size={20} />
        </p>
      )}
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm options={options} />
        </Elements>
      )}
    </>
  )
}

StripeCheckout.propTypes = {
  values: PropTypes.object.isRequired,
}
