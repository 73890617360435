import { Box, Typography, Button, Avatar } from "@mui/material"
import { grey } from "@mui/material/colors"
import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material"
import { useDispatch, useSelector } from "react-redux"
import { handleMonthIndex, selectMonthIndex } from "../../redux/slices/calendar"
import dayjs from "dayjs"
import { selectVehicleToRental } from "../../redux/slices/vehicles"
import { BASE_URI } from "../../constant/uris"

export const Header = () => {
  const dispatch = useDispatch()
  const monthIndex = useSelector(selectMonthIndex)
  const vehicle = useSelector(selectVehicleToRental)

  const handlePrevMonth = () => {
    dispatch(handleMonthIndex(monthIndex - 1))
  }

  const handleNextMonth = () => {
    dispatch(handleMonthIndex(monthIndex + 1))
  }

  const handleReset = () => {
    dispatch(handleMonthIndex(dayjs().month()))
  }

  return (
    <Box
      component={"header"}
      sx={{
        display: "flex",
        alignItems: "center",
        padding: "2px 4px 2px 4px",
      }}
    >
      <Avatar
        alt={`${vehicle.properties.model} ${vehicle.properties.year}`}
        src={`${BASE_URI}${vehicle.photos.FRONT.img}`}
        sx={{ width: 30, height: 30, marginRight: "1rem" }}
      />
      <Typography
        component={"h1"}
        fontWeight={"bold"}
        color={grey[500]}
        sx={{ marginRight: 5 }}
      >
        {vehicle.properties.model} {vehicle.properties.year}
      </Typography>
      <Button
        variant={"contained"}
        sx={{ marginRight: 2 }}
        onClick={handleReset}
      >
        Hoy
      </Button>
      <Button variant={"text"} onClick={handlePrevMonth}>
        <ArrowLeftOutlined fontSize='large' />
      </Button>
      <Typography
        component='h2'
        fontWeight={"bold"}
        color={grey[500]}
        sx={{ marginX: 2 }}
      >
        {dayjs(`${dayjs().year()}-${monthIndex}`).format("MMMM YYYY")}
      </Typography>
      <Button variant={"text"} onClick={handleNextMonth}>
        <ArrowRightOutlined fontSize='large' />
      </Button>
    </Box>
  )
}
