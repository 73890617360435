import { createSlice } from "@reduxjs/toolkit"
import { rentalsApi } from "../apis/rentals"

const initialState = {
  rental: null,
  list: [],
  ranting: 0,
}

const slice = createSlice({
  name: "rentalsSlice",
  initialState,
  reducers: {
    handleRental: (state, { payload }) => {
      localStorage.setItem("rental", JSON.stringify(payload))
      state.rental = payload
    },
    setComment: (state, { payload }) => {
      let aux = state.rental
      if (!state.rental) {
        aux = JSON.parse(localStorage.getItem("rental"))
      }

      if (aux.id === payload.id) {
        if (payload.comment_partner) {
          aux.comment_partner = payload.comment_partner
        }

        if (payload.comment_client) {
          aux.comment_client = payload.comment_client
        }
      }

      state.rental = aux
    },
    handleRanting: (state, { payload }) => {
      state.ranting = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        rentalsApi.endpoints.getRentalsListByUser.matchFulfilled,
        (state, action) => {
          const { op, rentals } = action.payload

          if (op && rentals) {
            let list = [...rentals]
            list.sort((a, b) => {
              return new Date(b.end) - new Date(a.end)
            })
            state.list = list
          }
        }
      )
      .addMatcher(
        rentalsApi.endpoints.startRental.matchFulfilled,
        (state, action) => {
          const { op, rental } = action.payload
          if (op && rental) {
            localStorage.setItem("rental", JSON.stringify(rental))
            state.rental = rental
          }
        }
      )
      .addMatcher(
        rentalsApi.endpoints.endRental.matchFulfilled,
        (state, action) => {
          const { op, rental } = action.payload
          if (op && rental) {
            localStorage.setItem("rental", JSON.stringify(rental))
            state.rental = rental
          }
        }
      )
  },
})

export const { handleRental, setComment } = slice.actions

export default slice.reducer

export const selectRentalsList = (state) => state.rentals.list
export const selectRental = (state) =>
  state.rentals.rental ?? JSON.parse(localStorage.getItem("rental"))
