import { Box, Button, ButtonBase } from "@mui/material"
import React, { useEffect, useState } from "react"
import { getMonth } from "../../utils/handleDate"
import { grey } from "@mui/material/colors"
import dayjs from "dayjs"
import { ArrowLeftOutlined, ArrowRightOutlined } from "@mui/icons-material"
import { useDispatch, useSelector } from "react-redux"
import {
  handleMonthIndex,
  handleSmallCalendarDaySelected,
  selectMonthIndex,
  selectSmallCalendarDaySelected,
} from "../../redux/slices/calendar"
import { blue } from "@mui/material/colors"
import { handleModal } from "../../redux/slices/helpers"

export const SmallCalendar = () => {
  const dispatch = useDispatch()
  const [currentMonthIndex, setCurrentMonthIndex] = useState(dayjs().month())
  const [currentMonth, setCurrentMonth] = useState(getMonth())
  const monthIndex = useSelector(selectMonthIndex)
  const daySelected = useSelector(selectSmallCalendarDaySelected)
  let isOldDay = false

  const handlePrevMonth = () => {
    setCurrentMonthIndex(currentMonthIndex - 1)
  }

  const handleNextMonth = () => {
    setCurrentMonthIndex(currentMonthIndex + 1)
  }

  const handleDayClick = (day) => {
    const format = "YYYY-MM-DD"
    dispatch(handleMonthIndex(currentMonthIndex))
    dispatch(handleSmallCalendarDaySelected(day.format(format)))
    dispatch(handleModal(true))
  }

  const getCurrentDayStyle = (day) => {
    const format = "YYYY-MM-DD"
    const isCurrentDay = day.isSame(new Date(), "day")
    const isDaySelected = day.format(format) === daySelected
    const oldDay = day.isBefore(new Date(), "day")
    isOldDay = false

    if (isCurrentDay) {
      return {
        backgroundColor: blue[500],
        borderRadius: 40,
        color: "white",
      }
    } else if (isDaySelected) {
      return {
        backgroundColor: blue[100],
        borderRadius: 40,
        color: blue[600],
        fontWeight: "bold",
      }
    } else if (oldDay) {
      isOldDay = true
      return {
        color: grey[500],
      }
    } else {
      return {}
    }
  }

  useEffect(() => {
    setCurrentMonthIndex(monthIndex)
  }, [monthIndex])

  useEffect(() => {
    setCurrentMonth(getMonth(currentMonthIndex))
  }, [currentMonthIndex])

  return (
    <Box component={"div"} mt={2}>
      <Box
        component={"header"}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button variant={"text"} size='small' onClick={handlePrevMonth}>
          <ArrowLeftOutlined fontSize='large' />
        </Button>
        <p style={{ fontWeight: "bold", color: grey[500] }}>
          {dayjs(`${dayjs().year()}-${currentMonthIndex}`).format("MMMM YYYY")}
        </p>
        <Button variant={"text"} size='small' onClick={handleNextMonth}>
          <ArrowRightOutlined fontSize='large' />
        </Button>
      </Box>

      <Box
        component={"div"}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(7, minmax(0, 1fr))",
          gridTemplateRows: "repeat(6, minmax(0, 1fr))",
        }}
      >
        {currentMonth[0].map((day, i) => (
          <span
            key={i}
            style={{
              fontSize: 10,
              paddingY: 1,
              textAlign: "center",
            }}
          >
            {day.format("dd").charAt(0)}
          </span>
        ))}

        {currentMonth.map((row, i) => (
          <React.Fragment key={i}>
            {row.map((day, index) => (
              <ButtonBase
                key={index}
                sx={[{ padding: 0, width: 1 }, getCurrentDayStyle(day)]}
                disabled={isOldDay}
                onClick={() => handleDayClick(day)}
              >
                <span>{day.format("D")}</span>
              </ButtonBase>
            ))}
          </React.Fragment>
        ))}
      </Box>
    </Box>
  )
}
