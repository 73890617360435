import { configureStore } from "@reduxjs/toolkit"
import { regionsApi } from "./apis/information/regions"
import { authApi } from "./apis/auth"
import authReducer from "./slices/auth"
import { setupListeners } from "@reduxjs/toolkit/query"
import { rolesApi } from "./apis/information/roles"
import errorsReducer from "./slices/errors"
import { viewsPermissionsApi } from "./apis/views_permissions"
import { usersApi } from "./apis/users"
import usersReducer from "./slices/users"
import { documentsApi } from "./apis/documents"
import { creditCardsApi } from "./apis/credit_cards"
import creditCardReducer from "./slices/credit_cards"
import vehiclesReducer from "./slices/vehicles"
import { vehiclesApi } from "./apis/vehicles"
import documentReducer from "./slices/documents"
import { addressesApi } from "./apis/addresses"
import addressesReducer from "./slices/addresses"
import helpersReducer from "./slices/helpers"
import { rentalsApi } from "./apis/rentals"
import rentalsReducer from "./slices/rentals"
import evidenceReducer from "./slices/evidences"
import calendarReducer from "./slices/calendar"

const store = configureStore({
  reducer: {
    [regionsApi.reducerPath]: regionsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    [viewsPermissionsApi.reducerPath]: viewsPermissionsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [documentsApi.reducerPath]: documentsApi.reducer,
    [creditCardsApi.reducerPath]: creditCardsApi.reducer,
    [vehiclesApi.reducerPath]: vehiclesApi.reducer,
    [addressesApi.reducerPath]: addressesApi.reducer,
    [rentalsApi.reducerPath]: rentalsApi.reducer,
    auth: authReducer,
    user: usersReducer,
    errors: errorsReducer,
    creditCard: creditCardReducer,
    vehicles: vehiclesReducer,
    document: documentReducer,
    addresses: addressesReducer,
    helpers: helpersReducer,
    rentals: rentalsReducer,
    evidences: evidenceReducer,
    calendar: calendarReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(regionsApi.middleware)
      .concat(authApi.middleware)
      .concat(rolesApi.middleware)
      .concat(usersApi.middleware)
      .concat(documentsApi.middleware)
      .concat(creditCardsApi.middleware)
      .concat(vehiclesApi.middleware)
      .concat(addressesApi.middleware)
      .concat(rentalsApi.middleware),
})

setupListeners(store.dispatch)

export default store
