import { Avatar, Box, Button, Icon, TextField, Typography } from "@mui/material"
import {
  checkIfNumber,
  dateParse,
  getNowLocalDate,
} from "../../utils/handleData"
import { useState } from "react"
// import { useVehicleRentalMutation } from "../../redux/apis/vehicles"
import { useSelector } from "react-redux"
import { selectVehicleToRental } from "../../redux/slices/vehicles"
import { selectSmallCalendarDaySelected } from "../../redux/slices/calendar"
import { BASE_URI } from "../../constant/uris"
import { StripeCheckout } from "../Rental/StripeCheckout"
// import dayjs from "dayjs"

const AddRental = () => {
  const vehicle = useSelector(selectVehicleToRental)
  const smallCalendarDaySelected = useSelector(selectSmallCalendarDaySelected)
  const [showStripe, setShowStripe] = useState(false)
  let auxSelectedDay = null

  const auxDay = getNowLocalDate()
  auxDay.setHours(auxDay.getHours() + 2)
  auxDay.setMinutes(0)
  auxDay.setSeconds(0)
  const toDay = auxDay.toISOString().split(".")

  if (smallCalendarDaySelected !== null) {
    const selectedDay = new Date(smallCalendarDaySelected)

    auxSelectedDay = selectedDay.toISOString().split(".")[0]
  }

  const [time, setTime] = useState(
    smallCalendarDaySelected ? auxSelectedDay : toDay[0]
  )
  const [values, setValues] = useState({
    vehicle: { id: vehicle.id },
    partner: { id: vehicle.user_id },
    start: dateParse(toDay[0]),
    days: 1,
  })

  // const [vehicleRental, { isLoading }] = useVehicleRentalMutation()

  const handleChange = (event) => {
    const { name, value } = event.target
    console.log("before", name, value)
    if (name === "start") {
      if (value < toDay[0]) return
      setValues({ ...values, start: dateParse(value) })
      setTime(value)
      return
    }
    if (name === "days" && value < 1) return
    setValues({ ...values, days: value })
    console.log("after", name, value, toDay[0])
  }

  const handleAddRental = () => {
    setShowStripe(true)
  }

  return (
    <Box component={"div"}>
      <Box
        component={"div"}
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant='h4'>
          Seleccione la fecha y los días de la renta
        </Typography>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Avatar
            alt={`${vehicle.properties.model} ${vehicle.properties.year}`}
            src={`${BASE_URI}${vehicle.photos.FRONT.img}`}
            sx={{ width: 80, height: 80, marginRight: "1rem" }}
          />
          <Typography variant='h6' sx={{ marginTop: "1rem" }}>
            {vehicle.properties.model} - {vehicle.properties.year} Costo: $
            {parseInt(vehicle.properties.price) * parseInt(values.days)}{" "}
            {vehicle.properties.currency}
          </Typography>
        </Box>
        <Box
          component={"div"}
          sx={{
            display: "flex",
            flexDirection: {
              sm: "column",
              md: "row",
            },
          }}
        >
          <Box
            component={"div"}
            style={{ justifyContent: "center" }}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "70vw",
              height: {
                sm: "auto",
                md: "60vh",
              },
              padding: "2rem",
            }}
          >
            <TextField
              fullWidth
              sx={{ marginTop: "1rem" }}
              name='start'
              label='Reservación Día y Hora'
              type='datetime-local'
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                value: time,
                min: toDay[0],
              }}
              onChange={handleChange}
            />

            <TextField
              fullWidth
              name='days'
              type='number'
              sx={{ marginTop: "1rem" }}
              label='Días que lo desea rentar'
              defaultValue={1}
              inputProps={{
                inputMode: "numeric",
                min: 1,
              }}
              onKeyDown={checkIfNumber}
              onChange={handleChange}
            />

            {!showStripe && (
              <Button
                startIcon={<Icon>attach_money</Icon>}
                variant='contained'
                sx={{ marginTop: "1rem" }}
                color='success'
                fullWidth
                onClick={handleAddRental}
              >
                Pagar
              </Button>
            )}
          </Box>
          {showStripe && <StripeCheckout values={values} />}
        </Box>
      </Box>
    </Box>
  )
}

export default AddRental
