import { Box } from "@mui/material"
// import { CreateEventButton } from "./CreateEventButton"
import { SmallCalendar } from "./SmallCalendar"
import { Modal } from "../General"
import AddRental from "./AddRental"

export const Sidebar = () => {
  return (
    <Box component={"aside"} sx={{ border: 1, padding: 2, minWidth: 120 }}>
      <Modal
        title='Selecciona la fecha de renta'
        description='fecha de renta del vehículo'
        textButton='+ Agregar renta'
        fullWidth={true}
        variant='contained'
      >
        <AddRental />
      </Modal>
      {/* <CreateEventButton /> */}
      <SmallCalendar />
    </Box>
  )
}
