import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import TakePicture from "../TakePicture/TakePicture";
import { useRegisterUserMutation } from "../../redux/apis/users";
import CircularProgress from "@mui/material/CircularProgress";
import SelectRegions from "../SelectRegions/SelectRegions";
import { areErrorsInputs } from "../../utils/validations";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addError } from "../../redux/slices/errors";
import PropTypes from "prop-types";

const FromLessor = ({ handleTypeUser }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [registerUser, { isLoading: isLoadingRegister }] =
    useRegisterUserMutation();
  // TODO: refactor to use array to render inputs
  // TODO: convert variables to objects to add error flags for showing errors in inputs
  // TODO: add show name to objects and use it in the form and error messages
  const [values, setValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirm: "",
    street: "",
    number: "",
    region: "",
    zip: "",
    ine: "",
    ine_expiration: "",
    license: "",
    license_expiration: "",
    proof_of_address: "",
    proof_of_address_date: "",
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleTakePicture = ({ name, base64 }) => {
    setValues({ ...values, [name]: base64 });
  };

  const handleRegisterUser = () => {
    const { op, errors } = areErrorsInputs(values);

    if (op) {
      dispatch(addError(errors));
      return false;
    }

    registerUser({
      type: "client",
      user: {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        password: values.password,
      },
      address: {
        street: values.street,
        number: values.number,
        region: { id: values.region },
        zip: values.zip,
      },
      documents: [
        {
          name: "ine",
          img: values.ine,
          expires_at: new Date(values.ine_expiration),
          type: "IDENTIFICATION",
        },
        {
          name: "license",
          img: values.license,
          expires_at: new Date(values.license_expiration),
          type: "LICENSE_DRIVER",
        },
        {
          name: "proof_of_address",
          img: values.proof_of_address,
          expires_at: new Date(values.proof_of_address_date),
          type: "PROOF_OF_ADDRESS",
        },
      ],
    })
      .then((res) => {
        const { error, data } = res;
        if (error) {
          dispatch(
            addError({
              kind: "fetch_error",
              severity: "error",
              message: error.data,
            })
          );
        }

        if (data) {
          navigate("/sign-in");
        }
      })
      .catch(() => {
        dispatch(
          addError({
            kind: "fetch_error",
            severity: "error",
            message:
              "Hubo un problema al tratar de registrarse por favor inténtelo más tarde",
          })
        );
      });
  };

  return (
    <Box>
      <Typography variant="h4">Información personal</Typography>
      {/* =========== Datos personales ========== */}
      <Box container component="form">
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="first_name"
            label="Nombre"
            variant="filled"
            value={values.first_name}
            onChange={handleChange("first_name")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="last_name"
            label="Apellido"
            variant="filled"
            value={values.last_name}
            onChange={handleChange("last_name")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="phone"
            label="Numero de celular"
            variant="filled"
            type="number"
            value={values.phone}
            onChange={handleChange("phone")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="email"
            label="Correo"
            variant="filled"
            type="email"
            value={values.email}
            onChange={handleChange("email")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="password"
            label="Contraseña"
            variant="filled"
            type="password"
            value={values.password}
            onChange={handleChange("password")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="confirm"
            label="Confirmar Contraseña"
            variant="filled"
            type="password"
            value={values.confirm}
            onChange={handleChange("confirm")}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectRegions region={values.region} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="street"
            label="Calle"
            variant="filled"
            value={values.street}
            onChange={handleChange("street")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="number"
            label="Numero exterior"
            variant="filled"
            value={values.number}
            onChange={handleChange("number")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="zip"
            label="Código postal"
            variant="filled"
            value={values.zip}
            onChange={handleChange("zip")}
          />
        </Grid>
        {/* ========== Papers ========== */}
        <Grid item container spacing={1}>
          <Grid item>
            <Typography variant="h4">Documentos</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <TakePicture
              name={"ine"}
              showName={"INE"}
              cameraWidth={640 / 2}
              cameraHeight={480 / 2}
              handleTakePicture={handleTakePicture}
            />
            <TextField
              fullWidth
              sx={{ marginTop: "1rem" }}
              name="ine_expiration"
              label="Fecha de expiración"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange("ine_expiration")}
            />
          </Grid>
          <Grid item>
            <TakePicture
              name={"license"}
              showName={"Licencia de conducir"}
              cameraWidth={640 / 2}
              cameraHeight={480 / 2}
              handleTakePicture={handleTakePicture}
            />
            <TextField
              fullWidth
              sx={{ marginTop: "1rem" }}
              name="license_expiration"
              label="Fecha de expiración"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange("license_expiration")}
            />
          </Grid>
          <Grid item>
            <TakePicture
              name={"proof_of_address"}
              showName={"Comprobante de domicilio"}
              cameraWidth={640 / 2}
              cameraHeight={480 / 2}
              handleTakePicture={handleTakePicture}
            />
            <TextField
              fullWidth
              sx={{ marginTop: "1rem" }}
              name="proof_of_address_date"
              label="Fecha del comprobante"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleChange("proof_of_address_date")}
            />
            <Typography sx={{ marginTop: "1rem" }} variant="body2">
              {" "}
              Menor a tres 3 meses de antigüedad
            </Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={1} mt={2}>
          <Grid item xs={12} md>
            <Button
              fullWidth
              variant="contained"
              color="error"
              onClick={() => handleTypeUser("")}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={12} md>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleRegisterUser}
            >
              {!isLoadingRegister ? "Registrarse" : <CircularProgress />}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

FromLessor.propTypes = {
  handleTypeUser: PropTypes.func.isRequired,
};

export default FromLessor;
