import dayjs from "dayjs"

export const getMonth = (month = dayjs().month()) => {
    const year = dayjs().year()
    const firstDayOfMonth = dayjs(`${year}-${month + 1}-01`).day()
    let currentMonthCount = 0 - firstDayOfMonth
    const daysMatrix = new Array(5).fill(0).map(() => new Array(7).fill(null).map(() => {
        currentMonthCount++
        return dayjs(`${year}-${month + 1}-${currentMonthCount}`)
    }))
    return daysMatrix
}