import { Box } from "@mui/material"
import PropTypes from "prop-types"
import { Day } from "./Day"
import React from "react"

export const Month = ({ month }) => {
  return (
    <Box
      component={"div"}
      sx={{
        flex: 1,
        display: "grid",
        gridTemplateColumns: "repeat(7, minmax(0, 1fr))",
        gridTemplateRows: "repeat(5, minmax(0, 1fr))",
      }}
    >
      {month.map((row, i) => (
        <React.Fragment key={i}>
          {row.map((day, index) => (
            <Day key={index} day={day} rowIdx={i} />
          ))}
        </React.Fragment>
      ))}
    </Box>
  )
}

Month.propTypes = {
  month: PropTypes.array,
}
