import { Box, Typography } from "@mui/material"
import { blue } from "@mui/material/colors"
import PropTypes from "prop-types"

export const Day = ({ day, rowIdx }) => {
  const getCurrentDayStyle = () => {
    const isCurrentDay = day.isSame(new Date(), "day")
    return isCurrentDay
      ? {
          backgroundColor: blue[600],
          borderRadius: 40,
          width: 40,
          height: 40,
          color: "white",
        }
      : {}
  }

  return (
    <Box
      component={"div"}
      sx={{
        display: "flex",
        flexDirection: "column",
        border: 1,
        borderColor: "grey",
      }}
    >
      <header
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {rowIdx === 0 && (
          <Typography component={"p"} mt={1}>
            {day.format("ddd").toUpperCase()}
          </Typography>
        )}
        <Typography
          component={"p"}
          p={1}
          my={1}
          textAlign={"center"}
          sx={getCurrentDayStyle()}
        >
          {day.format("DD")}
        </Typography>
      </header>
    </Box>
  )
}

Day.propTypes = {
  day: PropTypes.object,
  rowIdx: PropTypes.number,
}
